import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { FiCheck } from "react-icons/fi";
import { Link, useRouteMatch } from "react-router-dom";
import { ReactComponent as ScoutIcon } from "../assets/icons/scout.svg";
import { ReactComponent as GroupIcon } from "../assets/icons/tag.svg";
import { CapitalizedMessage } from "../localization";
import { DeviceContext } from "../state/DeviceContext";
import DeviceGroupContext from "../state/DeviceGroupContext";
import NotificationsContext from "../state/NotificationsContext";
import ConnectionStatus from "./ConnectionStatus";
import Gauge from "./Gauge";
import { IconBell, IconFunnel } from "./Icons";
import { PopupSection } from "./Popup";
import Table from "./Table";
import { useInterval } from "../hooks/useInterval";
import { buildRequest } from "../api";
import { AuthContext } from "../state/AuthContext";
import { useIntl } from "react-intl";

const UPDATE_INTERVAL = 20000;

export default function ScoutList({
  scoutIDs,
  groupIDs,
  onSelect,
  editID,
  minMax,
  siteID,
  disableRefresh,
  showNoGroups,
  ...rest
}) {
  const { getNotificationsForDevice } = useContext(NotificationsContext);
  const { scouts, loading: scoutsLoading } = useContext(DeviceContext);
  const { groups, loading: groupsLoading } = useContext(DeviceGroupContext);

  const { authenticatedFetch } = useContext(AuthContext);
  const [updatedScouts, updateScouts] = useState(scouts);
  const [updatedGroups, updateGroups] = useState(groups);
  const qs = JSON.stringify({ site: siteID });

  const intl = useIntl()

  useEffect(() => {
    updateScouts(scouts);
  }, [scouts]);

  useEffect(() => {
    updateGroups(groups);
  }, [groups]);

  // Get devices and filter networkDevices
  const fetchDevicesAndGroups = useCallback(() => {
    authenticatedFetch(
      buildRequest("GET").withPath(`/devices/`).withQuery(JSON.parse(qs))
    ).then((scouts) => {
      const filtered = scouts?.filter((scout) => scout.device_type === "hydra");
      updateScouts(filtered);
    });
    authenticatedFetch(
      buildRequest("GET").withPath("/groups/").withQuery(JSON.parse(qs))
    ).then(updateGroups);
  }, [authenticatedFetch, qs]);

  useInterval(async () => {
    !disableRefresh && fetchDevicesAndGroups();
  }, UPDATE_INTERVAL);

  useEffect(() => !disableRefresh && fetchDevicesAndGroups(), [
    fetchDevicesAndGroups,
    disableRefresh,
  ]);

  const siteMatch = useRouteMatch("/sites/:site_id/");
  const [filtered, setFiltered] = useState({
    name: true,
    moisture: true,
    temperature: false,
    salinity: false,
    water_balance: false,
    battery: false,
    status: true,
    has_notification: true,
    more: true,
    group: true,
  });

  const [kindFilter, setKindFiltered] = useState("all");
  const [statusFilter, setStatusFilter] = useState("all");
  const [alertFilter, setAlertFilter] = useState("all");

  const alertsMap = useMemo(
    () =>
      Object.assign(
        {},
        ...updatedScouts.map((scout) => ({
          [scout.id]: getNotificationsForDevice(scout).find(
            ({ resolved }) => !resolved
          ),
        }))
      ),
    [updatedScouts, getNotificationsForDevice]
  );

  const toggle = useCallback(
    (id, value) =>
      setFiltered({
        ...filtered,
        ...{
          moisture: false,
          temperature: false,
          salinity: false,
          water_balance: false,
          battery: false,
          oxygen: false,
        },
        [id]: value,
      }),
    [filtered]
  );

  const FilterMenu = ({
    options,
    messagePrefix,
    doFilter,
    isFiltered,
    titleId,
  }) => (
    <PopupSection titleId={titleId || "table.action.show"}>
      {options.map((id) => (
        <button
          className="justify-between"
          key={`btn-${id}`}
          onClick={() => {
            doFilter(id, true);
          }}
        >
          <CapitalizedMessage
            className={isFiltered(id) ? " text-scout-blue font-medium" : null}
            id={messagePrefix + "." + id}
          />
          {isFiltered(id) && <FiCheck className="text-scout-blue" size={18} />}
        </button>
      ))}
    </PopupSection>
  );

  const MeasurementsFilter = () => (
    <FilterMenu
      options={scouts.some(obj => obj.protocol_version === "209") ? ["moisture", "temperature", "salinity", "water_balance", "battery", "oxygen"] : ["moisture", "temperature", "salinity", "water_balance", "battery"]}
      messagePrefix="measurement"
      doFilter={toggle}
      isFiltered={(id) => filtered[id]}
    />
  );

  const sortScouts = useCallback(
    ({ original: a }, { original: b }, columnId, desc) => {
      if (a.group && !b.group) {
        return desc ? 1 : -1;
      } else if (!a.group && b.group) {
        return desc ? -1 : 1;
      }

      return a.name.localeCompare(b.name);
    },
    []
  );

  const someNotConnected = updatedScouts.some(
    (obj) => obj?.device_status === "NOT_CONNECTED"
  );

  const someHasNotification = useMemo(
    () => Object.values(alertsMap).some((val) => val),
    [alertsMap]
  );

  const siteURL = siteMatch?.url;
  const linkToRule = useCallback(
    (rule_id) => (siteURL ? `${siteURL}/alerts/view/${rule_id}` : null),
    [siteURL]
  );

  const columns = useMemo(
    () =>
      [
        {
          id: "group",
          Header: (
            <IconFunnel
              className={
                [kindFilter, statusFilter, alertFilter].some(
                  (val) => val !== "all"
                ) && "text-scout-blue"
              }
            />
          ),
          noChevron: true,
          headerMenu: (
            <>
              <FilterMenu
                options={["all", "scout", "group"]}
                messagePrefix="scoutfilter"
                doFilter={(id) => setKindFiltered(id)}
                isFiltered={(id) => kindFilter === id}
                titleId={"table.filter.groups"}
              />

              <FilterMenu
                options={["all", "NOT_CONNECTED", "WAITING", "OK"]}
                messagePrefix="connection_status"
                doFilter={(id) => setStatusFilter(id)}
                isFiltered={(id) => statusFilter === id}
                titleId={"table.filter.status"}
              />

              <FilterMenu
                options={["all", "alerting"]}
                messagePrefix="alert_status"
                doFilter={(id) => setAlertFilter(id)}
                isFiltered={(id) => alertFilter === id}
                titleId={"table.filter.alerting"}
              />
            </>
          ),
          accessor: "group",
          sortType: "basic",
          disableSortBy: true,
          Cell: (cell) =>
            cell.value ? (
              <GroupIcon className="fill-current text-scout-green-dark" />
            ) : (
              <ScoutIcon className="fill-current text-scout-blue" />
            ),
        },
        someNotConnected
          ? {
              id: "status",
              Header: null,
              accessor: "device_status",
              Cell: (cell) =>
                cell.row.original.group ? null : (
                  <ConnectionStatus device={cell.row.original} />
                ),
            }
          : {},
        someHasNotification
          ? {
              id: "has_notification",
              Header: null,
              accessor: "notification",
              Cell: (cell) =>
                cell.row.original.group ? null : cell.value ? (
                  <Link to={linkToRule(cell.value.rule_id)}>
                    <IconBell
                      title= {intl.formatMessage({id:"device.alerts.active_alert"})}
                      className="text-scout-red-light"
                    />
                  </Link>
                ) : null,
            }
          : {},
        {
          id: "name",
          Header: intl.formatMessage({id:"scout_list.header.name"}),
          accessor: "name",
          sortIndicator: "alpha",
          sortType: sortScouts,
          Cell: (cell) => <p className="w-32 sm:w-48">{cell.value}</p>,
        },
        {
          accessor: "group",
          Cell: (cell) => <p>{cell === true}</p>,
        },
        {
          id: "moisture",
          Header: intl.formatMessage({id:"scout_list.header.moisture"}),
          headerMenu: <MeasurementsFilter />,
          accessor: `last_measurement.moisture`,
          className: "w-full",
          sortIndicator: "numeric",
          Cell: ({ cell }) => (
            <Gauge
              className="bg-gradient-l-moisture"
              value={cell.value}
              lastMeasurement={cell.row.original.last_measurement}
              measuredVariable="moisture"
            />
          ),
          sortType: "basic",
        },
        {
          id: "temperature",
          Header: intl.formatMessage({id:"scout_list.header.temperature"}),
          headerMenu: <MeasurementsFilter />,
          accessor: "last_measurement.temperature",
          sortType: "basic",
          className: "w-full",
          sortIndicator: "numeric",
          Cell: ({ cell }) => (
            <Gauge
              className="bg-gradient-l-temperature"
              value={cell.value}
              lastMeasurement={cell.row.original.last_measurement}
              measuredVariable="temperature"
            />
          ),
        },
        {
          id: "salinity",
          Header: intl.formatMessage({id:"scout_list.header.salinity"}),
          headerMenu: <MeasurementsFilter />,
          accessor: "last_measurement.salinity",
          sortType: "basic",
          className: "w-full",
          sortIndicator: "numeric",
          Cell: ({ cell }) => (
            <Gauge
              className="bg-gradient-l-salinity"
              value={cell.value}
              lastMeasurement={cell.row.original.last_measurement}
              measuredVariable="salinity"
            />
          ),
        },
        {
          id: "water_balance",
          Header: intl.formatMessage({id:"scout_list.header.water_balance"}),
          headerMenu: <MeasurementsFilter />,
          accessor: "last_measurement.water_balance",
          sortType: "basic",
          className: "w-full",
          sortIndicator: "numeric",
          Cell: ({ cell }) => (
            <Gauge
              className="bg-gradient-l-water_balance"
              value={cell.value}
              lastMeasurement={cell.row.original.last_measurement}
              measuredVariable="water_balance"
            />
          ),
        },
        {
          id: "battery",
          Header: intl.formatMessage({id:"scout_list.header.battery"}),
          headerMenu: <MeasurementsFilter />,
          accessor: "voltage_battery",
          sortType: "basic",
          className: "w-full",
          sortIndicator: "numeric",
          Cell: ({ cell }) =>
            cell.row.values?.group ? null : (
              <Gauge
                className="bg-gradient-l-voltage"
                value={cell.value}
                lastMeasurement={cell.row.original.last_measurement}
                measuredVariable="voltage_battery"
              />
            ),
        },
        {
          id: "oxygen",
          Header: intl.formatMessage({id:"scout_list.header.oxygen"}),
          headerMenu: <MeasurementsFilter />,
          accessor: "last_measurement.oxygen",
          sortType: "basic",
          className: "w-full",
          sortIndicator: "numeric",
          Cell: ({ cell }) =>
            cell.row.values?.group ? null : (
              <Gauge
                className="bg-gradient-l-voltage"
                value={cell.value}
                lastMeasurement={cell.row.original.last_measurement}
                measuredVariable="oxygen"
              />
            ),
        },

      ].filter(({ id }) => filtered[id]),
    [
      filtered,
      someNotConnected,
      kindFilter,
      statusFilter,
      alertFilter,
      sortScouts,
      someHasNotification,
      linkToRule,
      intl
    ]
  );

  const data = useMemo(() => {
    const scoutData = updatedScouts
      ? updatedScouts.map((scout) => ({
          ...scout,
          notification: alertsMap[scout.id],
        }))
      : [];
    const groupData = showNoGroups
      ? []
      : updatedGroups
      ? updatedGroups.map((group) => ({
          ...group,
          group: true,
        }))
      : [];

    const kindFiltered =
      kindFilter === "all"
        ? groupData.concat(scoutData)
        : kindFilter === "scout"
        ? scoutData
        : groupData;

    const statusFiltered =
      statusFilter && statusFilter !== "all"
        ? kindFiltered.filter((obj) => obj?.device_status === statusFilter)
        : kindFiltered;

    return alertFilter && alertFilter !== "all"
      ? statusFiltered.filter((obj) => obj?.notification)
      : statusFiltered;
  }, [
    updatedScouts,
    updatedGroups,
    kindFilter,
    statusFilter,
    alertFilter,
    alertsMap,
    showNoGroups,
  ]);

  const selectedRowIds = useMemo(
    () =>
      scoutIDs && groupIDs
        ? Object.assign(
            {},
            ...data
              .map((obj, idx) =>
                (!obj.group && scoutIDs.includes(obj.id)) ||
                (obj.group && groupIDs.includes(obj.id))
                  ? { [idx]: true }
                  : null
              )
              .filter((val) => val !== null)
          )
        : [],
    [data, groupIDs, scoutIDs]
  );

  const highlightIdx = data.findIndex(({ id }) => id === editID);

  return (
    <Table
      selectedRowIds={selectedRowIds}
      highlightIdx={highlightIdx !== -1 ? highlightIdx : null}
      columns={columns}
      data={data}
      loading={scoutsLoading || groupsLoading}
      {...rest}
    />
  );
}
