import React, { useState } from "react";
import Spinner from "./Spinner";


const SubmitButton = ({ children, onClick,  ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
    
    const handleClick = async (e) => {
        if (onClick) {
            setIsLoading(true);
        await onClick(e);
            setIsLoading(false);
        }
    };

    return (
        <button
            onClick={handleClick}
            disabled={isLoading}
            {...props}
        >
            {isLoading ? <Spinner/> : children}
        </button>
        );
    };

export default SubmitButton;