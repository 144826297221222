import { subDays, differenceInDays, endOfDay, startOfDay } from "date-fns/esm";
import { stringify } from "query-string";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import {
  NumberParam,
  DateTimeParam,
  DelimitedNumericArrayParam,
  encodeQueryParams,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { DeviceContext } from "../state/DeviceContext";
import DeviceGroupContext from "../state/DeviceGroupContext";
import { UserContext } from "../state/UserContext";
import DashContent from "./DashContent";
import DashHeader, { IconLink, TitleContainer } from "./DashHeader";
import DateTimeSelector from "./DateTimeSelector";
import DashCards from "./DashCards";
import GroupDetails from "./GroupDetails";
import { SectionHeading } from "./Headings";
import { IconChevron, IconEdit, IconInfo, IconLeft } from "./Icons";
import Popup, { PopupSection } from "./Popup";
import ResponsiveSwitchView, { MediaContext } from "./ResponsiveSwitchView";
import ScoutDetails from "./ScoutDetails";
import ScoutList from "./ScoutList";
import useLocalStorage from "../hooks/useLocalStorage";
import { differenceInMinutes } from "date-fns";
import { DEFAULT_TIMESPAN } from "../utility/common";

var domain = null; // zoom-level (domain in billboardjs represents an axis)

export default function ScoutView({ site }) {
  const { url, path } = useRouteMatch();
  const editMatch = useRouteMatch([
    `${url}/edit/scouts/:scout_id`,
    `${url}/edit/groups/:group_id`,
    `${url}/edit/scouts/`,
    `${url}/edit/groups/`,
  ]);
  const [timeSpan, setTimeSpan] = useLocalStorage("timespan", DEFAULT_TIMESPAN);
  const { currentUser } = useContext(UserContext);
  const history = useHistory();
  const { scouts, rainSensors } = useContext(DeviceContext);
  const { groups } = useContext(DeviceGroupContext);

  const now = new Date();
  const queryMap = {
    scouts: withDefault(DelimitedNumericArrayParam, []),
    groups: withDefault(DelimitedNumericArrayParam, []),
    rainsensors: withDefault(DelimitedNumericArrayParam, []),
    from: withDefault(DateTimeParam, subDays(now, timeSpan)),
    to: withDefault(DateTimeParam, endOfDay(now)),
    sort: withDefault(StringParam, "name"),
    preset: withDefault(NumberParam, timeSpan),
  };

  const [query, setQuery] = useQueryParams(queryMap);
  const search = stringify(encodeQueryParams(queryMap, query));
  const sortSearch = stringify(
    encodeQueryParams(queryMap, { sort: query.sort })
  );
  const searchWithoutSelection = stringify(
    encodeQueryParams(queryMap, {
      from: query.from,
      to: query.to,
      sort: query.sort,
    })
  );

  const preset = query.preset;
  let timeStart = query.from;
  let timeEnd = query.to;
  let shouldUpdate = true;

  (function updateParamsByPreset() {
    const REFRESH_INTERVAL = 1;
    if (preset === -1) {
      shouldUpdate = false;
    }
    if (preset > 0) {
      let newStart = subDays(now, preset);
      let diffInMins = differenceInMinutes(startOfDay(newStart), startOfDay(timeStart));
      if (diffInMins > REFRESH_INTERVAL) {
        setQuery({ from: newStart, to: endOfDay(now) });
      } else {
        shouldUpdate = false;
      }

    }
  })();



  domain = [timeStart, timeEnd];

  const scoutIDs = query.scouts;
  const groupIDs = query.groups;
  const rainSensorIDs = query.rainsensors;

  const selectedScouts = useMemo(
    () => (scouts ? scouts.filter(({ id }) => scoutIDs.includes(id)) : []),
    [scouts, scoutIDs]
  );
  const selectedGroups = useMemo(
    () => (groups ? groups.filter(({ id }) => groupIDs.includes(id)) : []),
    [groups, groupIDs]
  );
  const selectedRainSensors = useMemo(
    () =>
      rainSensors
        ? rainSensors.filter(({ id }) => rainSensorIDs.includes(id))
        : [],
    [rainSensors, rainSensorIDs]
  );
  const amountSelected = selectedScouts.length + selectedGroups.length;

  const sortJson = JSON.stringify(query.sort);
  const sortBy = useMemo(() => {
    const sort = JSON.parse(sortJson);
    return [{ id: sort.replace(/^-/, ""), desc: sort[0] === "-" }];
  }, [sortJson]);

  const toggleRowSelected = useCallback(
    (object, selected, clear) => {
      const [name, elems] = object.group
        ? ["groups", clear ? [object.id] : groupIDs]
        : ["scouts", clear ? [object.id] : scoutIDs];
      setQuery({
        [name]: (selected
          ? elems.concat([object.id])
          : elems.filter((id) => id !== object.id)
        ).sort((a, b) => a > b),
      });
    },
    // FIXME: setQuery
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scoutIDs, groupIDs]
  );

  const toggleRainSensor = useCallback(
    (object, selected) => {
      setQuery({
        rainsensors: (selected
          ? rainSensorIDs.concat([object.id])
          : rainSensorIDs.filter((id) => id !== object.id)
        ).sort((a, b) => a > b),
      });
    },
    [rainSensorIDs, setQuery]
  );

  const zoomStore = useRef();
  const [zoomed, setZoomed] = useState(false);

  const zoom = ([from, to]) => {
    const prevDomain = domain;
    setQuery({ from, to });
    if (!zoomStore.current) {
      zoomStore.current = prevDomain;
    }
    setZoomed(true);
  };

  const editID = editMatch?.params
    ? Number(editMatch.params.scout_id) || Number(editMatch.params.group_id)
    : null;

  const tableControl = useMemo(
    () => ({
      toggleSortBy: (name, desc) =>
        setQuery({ sort: `${desc ? "-" : ""}${name}` }),
      clearSortBy: () => setQuery({ sort: null }),
      toggleRowSelected,
    }),
    // FIXME: SetQuery unnecessarily recalculates zoom
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggleRowSelected]
  );
  const editRow = useCallback(
    (obj) => {
      history.push({
        pathname: `${url}/edit/${obj.group ? "groups" : "scouts"}/${obj.id}/`,
        search: sortSearch,
      });
    },
    [history, url, sortSearch]
  );

  const sidebar = (
    <MediaContext.Consumer>
      {({ large }) =>
        !large && amountSelected > 0 ? (
          <Redirect to={{ pathname: `${url}/view`, search }} />
        ) : (
          <>
            <DashHeader shadow solid>
              <SectionHeading>
                <FormattedMessage id="views.scout_view.heading" />
              </SectionHeading>
              {!currentUser.read_only && (
                <Popup
                  trigger={
                    <button className="btn btn-green flex items-center">
                      <FormattedMessage id="button.action.new" />
                      <IconChevron className="ml-2" />
                    </button>
                  }
                >
                  <PopupSection titleId="button.action.new">
                    <Link to={`${url}/edit/scouts/`}>
                      <FormattedMessage id="device.scout" />
                    </Link>
                    <Link to={`${url}/edit/groups/`}>
                      <FormattedMessage id="device_group" />
                    </Link>
                  </PopupSection>
                </Popup>
              )}
            </DashHeader>
            <DashContent shadow solid>
              <ScoutList
                {...(!editMatch
                  ? {
                    scoutIDs,
                    groupIDs,
                  }
                  : {})}
                editID={editID}
                {...(large
                  ? {
                    rowSelect: true,
                    controlled: tableControl,
                    editRow,
                  }
                  : {
                    linkTo: (obj) =>
                      obj.group
                        ? `${url}/view?groups=${obj.id}`
                        : `${url}/view?scouts=${obj.id}`,
                  })}
                sortBy={sortBy}
                siteID={site.id}
              />
            </DashContent>
          </>
        )
      }
    </MediaContext.Consumer>
  );

  const refreshData = () => {
    const from = timeStart;
    const to = new Date();
    setQuery({ from, to });
  }

  const singleSelect =
    selectedScouts.length === 1 && selectedGroups.length === 0
      ? [selectedScouts[0].name, `${url}/edit/scouts/${selectedScouts[0].id}/`]
      : selectedScouts.length === 0 && selectedGroups.length === 1
        ? [selectedGroups[0].name, `${url}/edit/groups/${selectedGroups[0].id}/`]
        : null;

  const datePickerRef = useRef();

  const toolbar = (
    <div className="flex flex-row">
      {!zoomed && differenceInDays(Date.now(), timeEnd) < 1 && (
        <button
          className="btn bg-white shadow-indigo mr-2"
          onClick={() => {
            const from = timeStart;
            const to = new Date();
            setQuery({ from, to });
          }}
        >
          <FormattedMessage id="button.update-graphs" />
        </button>
      )}
      {zoomed && (
        <button
          className="btn bg-white shadow-indigo mr-2"
          onClick={() => {
            const [from, to] = zoomStore.current;
            zoomStore.current = null;
            setZoomed(false);
            setQuery({ from, to });
          }}
        >
          <FormattedMessage id="button.reset-zoom" />
        </button>
      )}
      <DateTimeSelector
        ref={datePickerRef}
        from={timeStart}
        to={timeEnd}
        preset={preset}
        onSubmit={(val) => {
          setQuery(val);
          zoomStore.current = null;
          setZoomed(false);
          val?.preset >= 0 && setTimeSpan(val.preset);
        }}
      />
    </div>
  );

  return (
    <ResponsiveSwitchView
      sidebar={sidebar}
      initial={<Redirect to={{ pathname: `${url}/view`, search }} />}
    >
      <Route path={`${path}/view`}>
        {amountSelected > 0 ? (
          <>
            <DashHeader>
              <TitleContainer>
                <IconLink
                  to={{ pathname: url, search: searchWithoutSelection }}
                  icon={<IconLeft />}
                />
                {singleSelect ? (
                  <>
                    <SectionHeading>{singleSelect[0]}</SectionHeading>
                    <IconLink
                      className="mb-1 ml-4"
                      to={singleSelect[1]}
                      icon={currentUser.read_only ? <IconInfo /> : <IconEdit />}
                    />
                    {!currentUser.read_only && (
                      <div className="mx-2">
                        <FormattedMessage id="button.edit" />
                      </div>
                    )}
                  </>
                ) : (
                  <SectionHeading>
                    <FormattedMessage id="scout_view.view.multiple" />
                  </SectionHeading>
                )}
              </TitleContainer>
              {toolbar}
            </DashHeader>
            <DashContent className="pt-4 px-4 sm:px-8 grid row-gap-6" grid>
              {!shouldUpdate && (
                <DashCards
                  scouts={selectedScouts}
                  groups={selectedGroups}
                  selectedRainSensors={selectedRainSensors}
                  toggleRainSensor={toggleRainSensor}
                  timeStart={timeStart}
                  timeEnd={timeEnd}
                  zoom={zoom}
                  datePickerRef={datePickerRef}
                  refreshData={refreshData}
                />
              )}
            </DashContent>
          </>
        ) : (
          <>
            <DashHeader>
              <SectionHeading>
                <FormattedMessage id="site.entire_site" />
              </SectionHeading>
              {toolbar}
            </DashHeader>
            <DashContent className="pt-4 px-4 sm:px-8 grid row-gap-6" grid>
              {!shouldUpdate && (
                <DashCards
                  scouts={scouts}
                  groups={groups}
                  selectedRainSensors={selectedRainSensors}
                  toggleRainSensor={toggleRainSensor}
                  timeStart={timeStart}
                  timeEnd={timeEnd}
                  zoom={zoom}
                  datePickerRef={datePickerRef}
                  aggregateAll
                  refreshData={refreshData}
                />
              )}
            </DashContent>
          </>
        )}
      </Route>
      <Route path={`${path}/edit`}>
        {({ match }) =>
          amountSelected > 0 ? (
            <Redirect
              to={{
                pathname: url,
                search,
              }}
            />
          ) : (
            <Switch>
              <Route
                path={[
                  `${match.path}/groups/:group_id/`,
                  `${match.path}/groups/`,
                ]}
              >
                <GroupDetails
                  site_id={site?.id}
                  linkTo={`/sites/${site?.id}/scouts`}
                  splitView
                />
              </Route>
              <Route
                path={[
                  `${match.path}/scouts/:scout_id/`,
                  `${match.path}/scouts/`,
                ]}
              >
                <ScoutDetails
                  site_id={site?.id}
                  linkTo={`/sites/${site?.id}/scouts`}
                  splitView
                />
              </Route>
            </Switch>
          )
        }
      </Route>
    </ResponsiveSwitchView>
  );
}
