import chroma from "chroma-js";
import * as d3 from "d3-array";
import React, { createContext, useCallback, useContext, useMemo } from "react";
import * as yup from "yup";
import { buildRequest } from "../api";
import useResource from "../hooks/useResource";
import schemaFields from "../state/schema";
import tailwindConfig from "../tailwindConfig";
import { AuthContext } from "./AuthContext";
import { SiteContext } from "./SiteContext";
import { useIntl } from "react-intl";

const DeviceContext = createContext();

//Localized text for antenna types
const useGetAntennaType = () => {
  const intl = useIntl();
  return (id) => intl.formatMessage({id: `device_form.antenna_types.${id}`});
}

const DeviceProvider = ({ children }) => {
  const { authenticatedFetch } = useContext(AuthContext);
  const { currentSite } = useContext(SiteContext);
  const [[{ entities: devices, loading }], crud] = useResource("devices", {
    site: currentSite.id,
  });

  const getAntennaType = useGetAntennaType();
  const antennaTypes = [
    {
      id: "unknown",
      value: getAntennaType("unknown"),
    },
    {
      id: "directive_yagi",
      value: getAntennaType("directive_yagi"),
    },
    {
      id: "omni_pole",
      value: getAntennaType("omni_pole"),
    },
    {
      id: "short_stub",
      value: getAntennaType("short_stub"),
    },
  ];

  // Used in table, needs to be memoized
  const scouts = useMemo(
    () =>
      devices
        ? devices.filter(({ device_type }) => device_type === "hydra")
        : [],
    [devices]
  );

  const rainSensors = useMemo(
    () =>
      devices
        ? devices.filter((device) => Number.isFinite(device.rain_mm_per_pulse))
        : [],
    [devices]
  );

  function updateDevices(newDevices) {
    devices.length = 0;
    for (var i in newDevices) devices.push(newDevices[i]);
  }

  // Same here
  const networkDevices = useMemo(
    () =>
      devices?.filter(({ device_type }) =>
        ["echo", "base"].includes(device_type)
      ),
    [devices]
  );

  // Measurements
  const getMeasurements = useCallback(
    (devices, qs) =>
      authenticatedFetch(
        buildRequest("GET")
          .withPath("/measurements/")
          .withQuery({
            ...qs,
            device: Array.isArray(devices)
              ? devices.map(({ id }) => id).join(",")
              : devices.id,
          })
      ),
    [authenticatedFetch]
  );

  const getMeasurementsDownsampled = useCallback(
    (devices, qs, version) =>
      authenticatedFetch(
        buildRequest("GET")
          .withPath("/measurements/aggregated/", version)
          .withQuery({
            ...qs,
            device: devices.map(({ id }) => id).join(","),
          })
      ),
    [authenticatedFetch]
  );

  const getMeasurementsCSV = useCallback(
    (devices, qs) =>
      authenticatedFetch(
        buildRequest("GET")
          .withPath("/measurements/csv/")
          .withQuery({
            ...qs,
            device: Array.isArray(devices)
              ? devices.map(({ id }) => id).join(",")
              : devices.id,
          })
      ),
    [authenticatedFetch]
  );

  const getMeasurementsCSVStatus = useCallback(
    (task_id, qs) => {
      return authenticatedFetch(
        buildRequest("GET")
          .withPath("/measurements/csv_task_status/")
          .withQuery({ task_id, ...qs })
      );
    },
    [authenticatedFetch]
  );

  const getSensorDataDownsampled = useCallback(
    (devices, qs) =>
      authenticatedFetch(
        buildRequest("GET")
          .withPath("/sensor_data/aggregated/")
          .withQuery({
            ...qs,
            device: devices.map(({ id }) => id).join(","),
          })
      ),
    [authenticatedFetch]
  );

  const getSensorDataSummed = useCallback(
    (devices, qs) =>
      authenticatedFetch(
        buildRequest("GET")
          .withPath("/sensor_data/summed/")
          .withQuery({
            ...qs,
            device: devices.map(({ id }) => id).join(","),
          })
      ),
    [authenticatedFetch]
  );

  const soilTypes = ["loam", "clay", "sand", "organic"];

  // Min/max latest values for the entire site
  const scoutMinMax = useMemo(
    () =>
      scouts
        ? Object.assign(
            {},
            ...["moisture", "temperature", "salinity"].map((name) => {
              const measList = scouts.map((scout) =>
                scout.last_measurement ? scout.last_measurement[name] : null
              );
              return {
                [name]: { min: d3.min(measList), max: d3.max(measList) },
              };
            })
          )
        : undefined,
    [scouts]
  );

  const getSerialValidator = (device_type) => {
    let result = schemaFields.validNumber;

    if (device_type === "hydra") {
      result = result.max(57344, "validation.serial");
    } else if (device_type === "echo") {
      result = result
        .min(57344, "validation.serial")
        .max(65519, "validation.serial");
    }

    return result;
  };

  const validationSchema = useCallback(
    ({ device_type }) =>
      yup.object().shape({
        name: yup.string().required("validation.required"),
        serial_number: getSerialValidator(device_type).typeError(
          "validation.number"
        ),
        device_type: yup.string().oneOf(["hydra", "echo", "base"]),
        ...(device_type === "base"
          ? {
              imei: yup
                .string()
                .required("validation.required")
                .matches(/\d*[0-9]$/, "validation.valid_base")
                .max(16, "validation.valid_base"),
            }
          : {}),
        latlon: yup.object().shape({
          latitude: schemaFields.latitude,
          longitude: schemaFields.longitude,
        }),
        antenna_type: yup.string().when("device_type", {
          is: () => device_type === "base" || device_type === "echo",
          then: yup.string().required("validation.required"),
        }),
        // Uncomment to validate orientation type
        // antenna_orientation: yup.number().when("antenna_type", {
        //   is: "directive_yagi",
        //   then: yup.number().required("validation.required"),
        // }),
        location: yup.object().shape({
          field_capacity: yup
            .number()
            .min(0, "validation.positive")
            .typeError("validation.number")
            .transform((v) => (isNaN(v) ? null : v)),
          wilting_point: yup.number().when("device_type", {
            is: () => device_type === "hydra",
            then: yup
              .number()
              .min(0, "validation.positive")
              .typeError("validation.number")
              .transform((v) => (isNaN(v) ? null : v))
              .notOneOf(
                [yup.ref("field_capacity"), null],
                "validation.fc_wp_equal"
              ),
            otherwise: yup.number().nullable(),
          }),
          irrigation_threshold: yup
            .number()
            .min(0, "validation.positive")
            .typeError("validation.number")
            .transform((v) => (isNaN(v) ? null : v)),
          height: yup
            .number()
            .nullable()
            .min(0, "validation.positive")
            .transform((value, originalValue) =>
              schemaFields.trimString(originalValue) === "" ? null : +value
            ),
          soil_type: yup.string().when("device_type", {
            is: "hydra",
            then: yup.string().oneOf(["loam", "clay", "sand", "organic"]),
            otherwise: yup.string().nullable(),
          }),
          soil_density: schemaFields.validNumber
            .nullable()
            .transform((value, originalValue) =>
              originalValue.trim() === "" ? null : Number(value)
            ),
          oxygen_current_calib: yup
            .number()
            .min(0, "validation.positive")
            .typeError("validation.number")
            .transform((v) => (isNaN(v) ? null : v)),
        }),
      }),
    []
  );

  const withColors = useMemo(() => {
    const color = (key) => tailwindConfig.theme.colors[key].default;
    if (scouts) {
      const colors = chroma
        .scale([color("scout-red"), color("scout-blue"), color("scout-green")])
        .mode("lch")
        .colors(scouts.length);
      return scouts
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((dev, idx) => ({ ...dev, color: colors[idx] }));
    }
  }, [scouts]);

  // TODO: deprecated
  const recalculateSoilType = useCallback(
    (deviceId, obj) =>
      authenticatedFetch(
        buildRequest("PATCH")
          .withPath(`/devices/${deviceId}/recalculate_measurements/`)
          .withBody(obj)
      ),
    [authenticatedFetch]
  );

  return (
    <DeviceContext.Provider
      value={{
        devices,
        scouts: withColors,
        rainSensors,
        networkDevices,
        entities: devices,
        loading: devices === undefined || loading,
        validationSchema,
        crud,
        getMeasurements,
        getMeasurementsDownsampled,
        getMeasurementsCSV,
        getMeasurementsCSVStatus,
        getSensorDataDownsampled,
        getSensorDataSummed,
        soilTypes,
        scoutMinMax,
        updateDevices,
        recalculateSoilType,
        antennaTypes,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

export { DeviceContext, DeviceProvider };
