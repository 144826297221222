import classNames from "classnames";
import React, { forwardRef } from "react";

const DashContent = forwardRef(
  (
    {
      className,
      dodge,
      solid = false,
      visible = true,
      children,
      splitView = false,
      span,
      noScroll,
      shadow,
      wFull
    },
    ref
  ) => (
    <div
      ref={ref}
      key={"content"}
      className={classNames(
        className,
        "flex flex-col items-center",
        { "bg-white": solid, "bg-transparent": !solid },
        { "inline-flex": visible, "hidden lg:inline-flex": !visible },
        { "hidden lg:inline-flex": dodge, "border-b": !dodge },
        { "pb-2 px-2 md:px-4 lg:items-start": splitView },
        { "col-span-2": span },
        { "md:overflow-y-auto": !noScroll },
        { "w-full": wFull},
        { shadow: shadow }
      )}
    >
      {children}
    </div>
  )
);

export default DashContent;
