export const groupsHaveDevice = (groups, protocol) => 
    groups.length > 0 && groups.some(group => group.devices_protocol_versions.includes(protocol));

export const scoutsHaveDevice = (scouts, protocol) => 
    scouts.length > 0 && scouts.some(scout => scout.protocol_version === protocol);

export const allGroupsHaveOnlyDevice = (groups, protocol) =>
    groups.length > 0 && groups.every(group => group.devices_protocol_versions.length === 1 && group.devices_protocol_versions.includes(protocol));

export const allScoutsHaveOnlyDevice = (scouts, protocol) =>
    scouts.length > 0 && scouts.every(scout => scout.protocol_version === protocol);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    groupsHaveDevice,
    scoutsHaveDevice,
    allGroupsHaveOnlyDevice,
    allScoutsHaveOnlyDevice,
}