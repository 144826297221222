import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import useModal from "../hooks/useModal";
import { UserContext } from "../state/UserContext";
import Card from "./Card";
import Confirmation from "./Confirmation";
import DashContent from "./DashContent";
import DashHeader, { IconLink, TitleContainer } from "./DashHeader";
import { DetailsContext } from "./DetailsView";
import { SectionHeading } from "./Headings";
import { IconLeft, IconEdit, IconInfo } from "./Icons";

const DetailsCol = ({
  linkTo,
  title,
  children,
  onDelete,
  withCard = true,
  withDelete = true,
  withButton = true,
  consentPrompt,
}) => {
  const history = useHistory();
  const [Modal, toggleModal] = useModal();
  const { resource, entity } = useContext(DetailsContext);
  const { currentUser } = useContext(UserContext);

  const { remove } = resource.crud;
  const { url } = useRouteMatch();
  const editUrl = url.replace("/view/", "/edit/");

  const handleDelete = () => {
    remove(entity)
      .then(() => {
        toast.success(<FormattedMessage id={"action.deleted"} />);
        history.push(linkTo);
        onDelete && onDelete(entity);
      })
      .catch((err) => toast.error(`Error: ${err.status} ${err.statusText}`));
  };

  return (
    <>
      {entity && withDelete && (
        <Modal>
          <Confirmation
            title={`Deleting ${entity.name}`}
            message={
              <p>
                Are you sure you want to delete <strong>{entity.name}</strong>?
              </p>
            }
            onDelete={handleDelete}
            consentPrompt={consentPrompt}
          />
        </Modal>
      )}
      <DashHeader span>
        <TitleContainer>
          <IconLink to={linkTo} icon={<IconLeft />} />
          <SectionHeading>{title}</SectionHeading>
          <IconLink
            to={editUrl}
            icon={currentUser.read_only ? <IconInfo /> : <IconEdit />}
          />
        </TitleContainer>
        {entity && withDelete && !currentUser.read_only && (
          <button onClick={toggleModal} className='btn btn-red'>
            <FormattedMessage id='dialog.button_delete' />
          </button>
        )}
      </DashHeader>
      <DashContent className='pt-6' span>
        {withCard ? (
          <Card className='max-w-3xl pt-3 pb-3'>{children}</Card>
        ) : (
          children
        )}
      </DashContent>
    </>
  );
};

export default DetailsCol;
